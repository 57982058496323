<template>
    <div class="h100">
        <div class="fonPlanet " >

                <!-- v-on:selectPlanet="param.planet = $event"  -->
            <NavComponent 
                :numCad="numCad" 
                @selectCad="numCad = $event" 
                @settings="settings=!settings; show=false" 
                v-on:newTask="newTask" />

            <div>
                <div class="preload" v-if="load">
                    <div id="squaresWaveG">
                        <div id="squaresWaveG_1" class="squaresWaveG"></div>
                        <div id="squaresWaveG_2" class="squaresWaveG"></div>
                        <div id="squaresWaveG_3" class="squaresWaveG"></div>
                        <div id="squaresWaveG_4" class="squaresWaveG"></div>
                        <div id="squaresWaveG_5" class="squaresWaveG"></div>
                        <div id="squaresWaveG_6" class="squaresWaveG"></div>
                        <div id="squaresWaveG_7" class="squaresWaveG"></div>
                        <div id="squaresWaveG_8" class="squaresWaveG"></div>
                    </div>
                </div>
                <table class="main_table text-center">
                    <thead>
                        <tr v-if="numCad == 0">
                            <!-- <td>Задание</td> -->
                            <td>Одноразовая</td>
                            <td>Планета</td>
                            <td>Период работы</td>
                            <!-- <td>Флот</td> -->
                            <td title="Количество флотов">Флоты</td>
                            <td>Диапазон планет </td>
                            <td>Улетело </td>
                            <td>Последний вылет </td>
                            <td>Статус</td>
                            <td>Действие </td>
                        </tr>
                        <tr v-if="numCad == 1">
                            <!-- <td>Задание</td> -->
                            <td>Название</td>
                            <td>Фильтр</td>
                            <td>Значение фильтра</td>
                            <!-- <td>Ник игрока</td> -->
                            <td>Категория</td>
                            <td>Поле</td>
                            <td>Кол-во</td>
                            <td>Сообщения</td>
                            <td>Действие </td>
                        </tr>
                    </thead>
                    <tbody v-if="numCad == 0" :key="'sky'">
                        <tr v-for="task in tasks" :key="task.id" :class="['task-status-' + task.status]">
                            <!-- <td>Исследование</td> -->
                            <td>{{task.disposable?'Да':'Нет'}}</td>
                            <td>{{task.planet}}</td>
                            <td v-if="task.pauseTo == task.pauseWith">Постоянно</td>
                            <td v-else>C {{getTimeObr(task.pauseTo)}} до {{getTimeObr(task.pauseWith)}} </td>
                            <!-- <td>разведзонд - {{task.spaceships}}</td> -->
                            <td title="Количество флотов">{{task.fleets}}</td>
                            <td>от [{{task.coordinatesFrom[0]}}:{{task.coordinatesFrom[1]}}:{{task.coordinatesFrom[2]}}] до [{{task.coordinatesTo[0]}}:{{task.coordinatesTo[1]}}:{{task.coordinatesTo[2]}}]</td>
                            <td>{{task.launches}}</td>
                            <td>{{new Date(task.lastActivities*1000).toLocaleString("ru")}}</td>
                            <td>{{ getStatusText(task.status) }}</td>
                            <td>
                                <div class="mr-3 d-inline-block">
                                    <span class="mr-1 cursor-pointer" v-if="task.status !== 'running'" @click="setSkyStatus(task.id, 'running')"><img src="@/assets/img/play.png" class="action-icon"></span>
                                    <span class="mr-1 cursor-pointer" v-if="task.status === 'running'" @click="setSkyStatus(task.id, 'pause')"><img src="@/assets/img/pause.png" class="action-icon"></span>
                                    <span class="mr-1 cursor-pointer" v-if="task.status !== 'deleted'" @click="setSkyStatus(task.id, 'deleted')"><img src="@/assets/img/stop.png" class="action-icon"></span>
                                </div>
                                <span class="mr-1 cursor-pointer" @click="editTask(task)"><img src="@/assets/img/edit-list.png" class="action-icon"></span>
                                <span @click="deleteTaskId = task.id" class="cursor-pointer"><img src="@/assets/img/multiplication-sign.png" class="action-icon"></span>
                            </td>
                        </tr>
                        <tr v-if="(!tasks || tasks.length < 1) && !load " key="'no_tasks'">
                            <td class="text-center" colspan="11">Нет активных задач</td>
                        </tr>
                    </tbody>
                    <tbody v-if="numCad == 1" :key="'signal'">
                        <tr v-for="task in tasks" :key="task.id">
                            <!-- <td>Маяк</td> -->
                            <td>{{task.name}}</td>
                            <td>{{purpose[task.purpose.type]}}</td>
                            <td>{{task.purpose.value?task.purpose.value:'-'}}</td>
                            <!-- <td>{{task.purposeUser}}</td> -->
                            <td>{{categorys[task.parameters.type].name}}</td>
                            <td>{{categorys[task.parameters.type].fields[task.parameters.name]}}</td>
                            <td>{{task.parameters.number}}</td>
                            <td><a @click="openLHouseMsgPopup(task.id)">{{ task.statistics.messagesAmount }} {{ task.statistics.existNewMessage ? '(новые!)' : '' }}</a></td>
                            <td>
                                <span class="mr-2 cursor-pointer" @click="editLigth(task)"><img src="@/assets/img/edit-list.png" style="height:17px;" alt=""></span>
                                <span @click="deleteLigth(task.id)" class="cursor-pointer"><img src="@/assets/img/multiplication-sign.png" style="height:17px;" alt=""></span>
                            </td>
                        </tr>
                        <tr v-if="(!tasks || tasks.length < 1) && !load " key="'no_tasks'">
                            <td class="text-center" colspan="11">Нет активных задач</td>
                        </tr>
                        <tr v-if="tasks.length < 1 && !isProVersion" key="'no_tasks_lite'">
                            <td class="text-center" colspan="11">Маяки доступны лишь в PRO-версии</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="newTask" v-if="settings">
                <div class="bb">
                    <p class="mb-1 text-center">Очередь открытия планет </p>
                </div>
                <div class="mt-2 d-flex flex-column bb" >
                    <div  class="mx-auto " v-for="(telescope, index) in getQueueTelescope" :key="index">
                        <div  class="my-1 d-flex"><div class="" style="min-width:80px;">{{telescope.order}}. Кластер </div> <input @change="changeVal(telescope.cluster, index)" type="number" class="time_piker text-center" style="width:50px;" v-model="telescope.cluster"></div>
                    </div>
                </div>
                <div class="mt20 w-100 d-flex pr-2 mt-3">
                    <button type="button" class="btn_task mr-auto" @click="settings=false" name="button">Закрыть</button>
                    <button type="button" class="btn_task " @click="setTelescope" :disabled="disabled" name="button">{{textBtn}}</button>
                </div>
            </div> -->
            <div class="newTask " v-if="show">
                <div class="close-cross" @click="newTask"><img class="action-icon" src="@/assets/img/multiplication-sign.png" alt=""></div>
                <div class="" v-if="numCad==0">
                    <div class="bb mt" style="padding-right: 14px;">
                        <p class="mb-1 ">Миссия с планеты</p>
                        <!-- Исследование -->
                        <select class="numInp w-100" v-model="param.planet.name" name="">
                            <option v-if="!param.planet.name" value="" :key="'planet_default'" disabled>Выберите планету</option>
                            <option
                                v-for="pl in userPlanets"
                                :value="pl.name"
                                :key="'planet_' + pl.id">{{ pl.name }}</option>
                        </select>
                    </div>
                    <!-- <p class="mb-1 text-center">Миссия с планеты <b style="text-transform: capitalize;">{{param.planet.name}}</b> </p> -->
                </div>
                <div class="bb mt" style="padding-right: 14px;">
                    <p class="mb-1 ">Задание</p>
                    <!-- Исследование -->
                    <select class="selectCad numInp w-100" v-model.number="numCad" name="">
                        <option value="0">Исследование</option>
                        <option value="1">Создать маяк</option>
                    </select>
                </div>
                <div v-if="numCad==0" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-8 p-0">
                            <p class="mb-0">
                                <label class="mb-0 mr-2 cursor-pointer" for="checkboxik">Выполнить задачу один раз</label>
                                <label class="my-0">
                                    <input v-model="param.disposable" id="checkboxik" type="checkbox"  >
                                    <span  class="pseudocheckbox"></span>
                                </label>
                            </p>
                        </div>
                        <div class="col-4 pb-2 ">

                        </div>
                    </div>
                </div>

                <div v-if="numCad==0" class="bb mt  " >
                    <p class="mb-1 ">Состояние владельца планеты</p>
                            <!-- Исследование -->
                            <select class="selectCad numInp w-100" v-model="param.owner" name="">
                                <option value="none" selected>не важно</option>
                                <option value="without">без владельца</option>
                                <option value="with">есть владелец</option>
                                <option value="wasweekago">владелец давно не заходил</option>
                            </select>
                            <!-- <p class="mb-0">
                                <label class="mb-0 mr-2 cursor-pointer" for="checkboxik2">Только на заселенные планеты</label>
                                <label class="my-0">
                                    <input v-model="param.withOwner" id="checkboxik2" type="checkbox"  >
                                    <span  class="pseudocheckbox"></span>
                                </label>
                            </p> -->
                    
                </div>
                <div v-if="numCad==0" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-8 p-0">
                            <p class="mb-0">Отправлять корабли по расписанию:</p>
                            <p class="mb-0">
                                <label class="mb-0 mr-2 cursor-pointer" for="checkboxik4">Постоянно</label>
                                <label class="my-0">
                                    <input v-model="always" id="checkboxik4" type="checkbox"  >
                                    <span  class="pseudocheckbox"></span>
                                </label>
                            </p>
                        </div>
                        <div class="col-4 pb-2 ">
                            <p class="mb-0 d-flex justify-content-end">
                                с <input v-on:focus="always = false" type="time" v-model="param.pauseWith" id="appt" :class="{'time_piker':1, 'ml-2':1, mutedz:always}" name="appt" >
                            </p>
                            <p class="mb-0 d-flex justify-content-end">
                                до <input v-on:focus="always = false" v-model="param.pauseTo" type="time" id="appt" :class="{'time_piker':1, 'ml-2':1, 'mt-1':1, mutedz:always}" name="appt" >
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="numCad==0" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-6 p-0">
                            <p class="mb-0">Отправлять в диапазоне планет:</p>

                        </div>
                        <div class="col-6 d-flex justify-content-end" style="">
                            <select class="numInp mx-1" v-model="numGalaxy1" name="" style="width:40px" :disabled="isCoorAdvMode">
                                <option v-for="(galax, index) in galaxy"  :key="index"  :value="galax">{{galax}}</option>
                            </select>
                            :
                            <select class="numInp mx-1" v-model="numSector1" name="" style="width:100px" :disabled="isCoorAdvMode">
                                <option v-for="(sect, index) in sector"  :key="index" :value="sect*100+'-'+(sect+1)*100">{{sect*100+'-'+(sect+1)*100}}</option>
                            </select>
                            :
                            <select class="numInp ml-1" v-model="numPlanet1" name="" style="width:60px" :disabled="isCoorAdvMode">
                                <option v-for="(planet, index) in planets"  :key="index" :value="'0-'+planet">{{'0-'+planet}}</option>
                            </select>
                            <span
                                class="popup-settings ml-1"
                                @click="activeIsCoorAdvMode()"><img src="~@/assets/img/settings.svg" alt=""></span>

                        </div>
                    </div>
                    <div class="advanced-coords pt-2" v-if="isCoorAdvMode">
                        <p class="mb-0 d-flex justify-content-end">с
                            <input type="number" @change="maxval(0)" @focus="fock(0, 'coordinatesFromArr')" @blur="blr(0,'coordinatesFromArr')" min="0" max="" name="" v-model.lazy.number="param.coordinatesFromArr[0]" class="inpCoard text-center time_piker mx-1" >:
                            <input type="number" @change="maxval(1)" @focus="fock(1, 'coordinatesFromArr')" @blur="blr(1, 'coordinatesFromArr')" min="0" name="" v-model.lazy.number="param.coordinatesFromArr[1]" class="inpCoard text-center time_piker mx-1" >:
                            <input type="number" @change="maxval(2)" @focus="fock(2, 'coordinatesFromArr')" @blur="blr(2, 'coordinatesFromArr')" min="0" max="20" name="" v-model.lazy.number="param.coordinatesFromArr[2]" class="inpCoard text-center time_piker ml-1" >
                        </p>
                        <p class="mb-0 d-flex justify-content-end mt-1">до
                            <input type="number" @change="minval(0)" @focus="fock(0, 'coordinatesToArr')" @blur="blr(0,'coordinatesToArr')" min=0 name="" v-model.lazy.number="param.coordinatesToArr[0]" class="inpCoard text-center time_piker mx-1" value="">:
                            <input type="number" @change="minval(1)" @focus="fock(1, 'coordinatesToArr')" @blur="blr(1,'coordinatesToArr')" min=0 name="" v-model.lazy.number="param.coordinatesToArr[1]" class="inpCoard text-center time_piker mx-1" value="">:
                            <input type="number" @change="minval(2)" @focus="fock(2, 'coordinatesToArr')" @blur="blr(2,'coordinatesToArr')" min=0 max="20" name="" v-model.lazy.number="param.coordinatesToArr[2]" class="inpCoard text-center time_piker ml-1" value="">
                        </p>
                    </div>
                </div>
                <div v-if="numCad==0" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-8 p-0">
                            <p class="mb-0">Количество одновременных вылетов:</p>
                        </div>
                        <div class="col-4">
                            <p class="mb-0 d-flex justify-content-end">
                                <input type="number" v-model="param.fleets" :class="{time_piker:1, nonnumber:1, 'text-center':1, mdidth:1, 'ml-2':1, error:error=='fleets'}" min="1"  value="">
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="numCad==0" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-8 p-0">
                            <p class="mb-0">Количество разведзондов в один флот:</p>
                        </div>
                        <div class="col-4">
                            <p class="mb-0 d-flex justify-content-end">
                                <input type="number" v-model="param.spaceships"  :class="{time_piker:1, nonnumber:1, 'text-center':1, 'ml-2':1, mdidth:1, error:error=='spaceships'}" min="1"  value="">
                            </p>
                        </div>
                    </div>
                </div>


                <div v-if="numCad==1" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-6 p-0">
                            <p class="mb-0">Название:</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 d-flex justify-content-end">
                                <input type="text" v-model="paramLitht.name"  :class="{time_piker:1, nonnumber:1, 'w-100':1, 'text-center':1, 'ml-2':1, mdidth:1, error:error=='nameLight'}"  value="">
                            </p>
                        </div>
                    </div>
                </div>
                <!-- фильтр -->
                                <div v-if="numCad==1" class="bb mt container-fluid " >
                                    <div class="row">
                                        <div class="col-6 p-0">
                                            <p class="mb-0">Фильтр</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="mb-0 pl-2 d-flex justify-content-end">
                                                <select class="selectCad text-center numInp w-100" style="text-align-last:center;" v-model="paramLitht.purpose.type" name="">
                                                    <option class="text-center"  value="0">Нет</option>
                                                    <option class="text-center"  value="1">По планете</option>
                                                    <option class="text-center"  value="2">По нику</option>
                                                    <option class="text-center"  value="3">По альянсу</option>
                                                </select>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                <!-- значние фильтра -->

                                <div v-if="numCad==1 && paramLitht.purpose.type != '0'" class="bb mt container-fluid " >
                                    <div class="row">
                                        <div class="col-6 p-0">
                                            <p class="mb-0" v-if="paramLitht.purpose.type == '1'">Навание планеты:</p>
                                            <p class="mb-0" v-if="paramLitht.purpose.type == '2'">Введите ник:</p>
                                            <p class="mb-0" v-if="paramLitht.purpose.type == '3'">Введите альянс:</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="mb-0 d-flex justify-content-end">
                                                <input  v-model="paramLitht.purpose.value" :class="{time_piker:1, nonnumber:1, 'w-100':1, 'text-center':1, 'ml-2':1, mdidth:1, error:error=='nameLight'}"  value="">
                                            </p>
                                        </div>
                                    </div>
                                </div>

                <!-- <div v-if="numCad==1" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-6 p-0">
                            <p class="mb-0">Ник игрока:</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 d-flex justify-content-end">
                                <input type="text" v-model="paramLitht.purposeUser"  :class="{time_piker:1, nonnumber:1, 'w-100':1, 'text-center':1, 'ml-2':1, mdidth:1, error:error=='nameLight'}"  value="">
                            </p>
                        </div>
                    </div>
                </div> -->
                <div v-if="numCad==1" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-6 p-0">
                            <p class="mb-0">Категория:</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 pl-2 d-flex justify-content-end">
                                <select class="selectCad numInp w-100" style="text-align-last:center;" v-model="paramLitht.parameters.type" name="">
                                    <option :value="index"  v-for="(category, index) in categorys" :key="index">{{category.name}}</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="numCad==1" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-6 p-0">
                            <p class="mb-0">Следить за полем:</p>
                        </div>
                        <div class="col-6 ">
                            <p class="mb-0  pl-2 d-flex justify-content-end">
                                <select class="selectCad text-center numInp w-100" style="text-align-last:center;" v-model="paramLitht.parameters.name" name="">
                                    <option class="text-center"  value="sel">Выберети поле</option>
                                    <option class="text-center" :value="index"  v-for="(field, index) in categorys[paramLitht.parameters.type].fields" :key="index">{{field}}</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="numCad==1" class="bb mt container-fluid " >
                    <div class="row">
                        <div class="col-6 p-0">
                            <p class="mb-0">Сработать когда значение достигнет:</p>
                        </div>
                        <div class="col-6">
                            <p class="mb-0 d-flex justify-content-end">
                                <input type="text"  v-model="paramLithtNumber" :class="{time_piker:1, nonnumber:1, 'mt-2':1, 'w-100':1, 'text-center':1, 'ml-2':1, mdidth:1, error:error=='nameLight'}"  value="">
                            </p>
                        </div>
                    </div>
                </div>


                <div class="mt20 w-100 d-flex pr-2 mt-3">
                    <button type="button" class="btn_task mr-auto" @click="newTask" name="button">Отменить</button>
                    <button type="button" class="btn_task " @click="createTask" :disabled="disabled" name="button">{{edit?"Сохранить":"Создать"}}</button>
                    <button type="button" v-if="param.type == 'telescope'" class="btn_task " @click="setTelescope" :disabled="disabled" name="button">{{textBtn}}</button>
                </div>
            </div>
            <div class="popupPodtMask" v-if="deleteTaskId" @click.stop="deleteTaskId = !deleteTaskId">
                <div class="h-100 w-100 position-relative">

                </div>
            </div>
            <div class="popupPodt p-3 text-white"  v-if="deleteTaskId">
                <h4 class="my-4">Вы точно хотите удалить?</h4>
                <div class="px-3 mb-4 mx-auto d-flex" >
                    <span @click="deleteTaskId = ''" style="width:100px" class="cursor-pointer btn_task mr-auto">Нет</span>
                    <span @click="deleteTask(deleteTaskId)" style="width:100px" class="cursor-pointer btn_task ">Удалить</span>
                </div>
            </div>

            <!-- popup with messages of lighthouse -->
            <div class="popupPodtMask" v-if="isShowLHouseMessagesPopup" @click.stop="closeLHouseMessagesPopup">
                <div class="h-100 w-100 position-relative"></div>
            </div>

            <div class="popupPodt popupPodt--msgs p-3 text-white"  v-if="isShowLHouseMessagesPopup">
                <h4>Сообщения</h4>
                <div class="msgs-list">
                    <div
                        v-for="msg in lhouseMessagesDescSorted"
                        :key="'lhousemsg_' + msg.id">
                        [{{ msg.time | dateLocale }}] {{ msg.message }}
                    </div>
                </div>
                <div class="mt-1 mx-auto d-flex justify-content-center">
                    <span @click="closeLHouseMessagesPopup" style="width:100px" class="cursor-pointer btn_task">Закрыть</span>
                </div>
            </div>
            <!-- popup with messages of lighthouse -->

        </div>
        <LogComponent class="LogComponent"/>
    </div>
</template>

<script type="text/javascript">
import NavComponent from '@/components/basic_component/NavComponent.vue'
import LogComponent from '@/components/basic_component/LogComponent.vue'
import categorys from '@/assets/js/categorys.js'

export default {
    name: 'Cad',
    components: {
        NavComponent,
        LogComponent,
        // SelectComponent
    },
    mounted(){
        this.token = localStorage.getItem("glxtoken")
        // this.getTelescope()
        this.getTasks()
        this.timerId = setInterval(this.getTasks, 30000);
    },
    computed: {
        galaxy(){
            return [...Array(40).keys()]
        },
        sector(){
            return [...Array(12).keys()]
        },
        planets(){
            return [...Array(21).keys()]
        },
        isShowLHouseMessagesPopup() {
            return this.lhouseMessages.length > 0
        },
        lhouseMessagesDescSorted() {
            return [...this.lhouseMessages].reverse()
        },
        isProVersion() {
            return this.$store.getters.isProVersion
        },
        userPlanets() {
            return this.$store.getters.planets
        },
        // selectedPlanetName() {
        //     return this.$store.getters.getSelectedPlanetName;
        // }
        // getQueueTelescope(){
        // 	console.log('queueTelescope',this.queueTelescope.slice(0, 9));
        // 	if (!this.queueTelescope) {
        // 		return []
        // 	}
        // 	return JSON.parse(JSON.stringify(this.queueTelescope.slice(0,9)))
        // },
    },
    filters: {
        dateLocale(dateString) {
            return new Date(dateString).toLocaleString()
        }
    },
    methods:{
        // changeVal(value, index){
        //
        // 	for (var i = 0; i < this.queueTelescope.length; i++) {s
        // 		if (this.queueTelescope[i].cluster == value) {
        // 			// console.log(this.queueTelescope[i].cluster, this.queueTelescope[index].cluster, i, index);
        // 			this.queueTelescope[i].cluster = this.queueTelescope[index].cluster
        // 			this.queueTelescope[index].cluster = value
        // 		}
        // 	}
        // 	console.log(this.getQueueTelescope);
        // },
        fock(id, arr){
            if (this.param[arr][id] == '0' || this.param[arr][id] == '100' || this.param[arr][id] == '20') {
                this.$set(this.param[arr], id, '')
            }
        },
        blr(id, arr){
            if (this.param[arr][id] == '') {
                this.$set(this.param[arr], id, '0')
            }
        },
        // getTelescope(){
        // 	this.$axios.get("/telescope", { headers: {"token": this.token},  }).then(res=>{
        // 		console.log(res.data)
        // 		this.queueTelescope = res.data.items
        // 	})
        //
        // },
        // setTelescope(){
        // 	this.disabled = true
        // 	this.$axios.post("/telescope", this.queueTelescope, { headers: {"token": this.token},  }).then(res=>{
        // 		this.settings = false
        // 		this.textBtn = 'Сохранено'
        // 		setTimeout(()=>{this.textBtn = 'Сохранить'}, 1000)
        // 		this.disabled = false
        // 		console.log(res)
        // 	})
        // },

        getTimeObr(time){
            let d = new Date()
            let gtm = parseInt(d.getTimezoneOffset()/60)
            let timeObr = time.split(" ")
            let timeLess = timeObr[0].split(":")
            if (timeObr[1] == 'PM') {
                d.setHours(parseInt(timeLess[0])+12-gtm,timeLess[1])
            }else {
                d.setHours(parseInt(timeLess[0])-gtm,timeLess[1])
            }

            return d.toLocaleString('ru', { hour: 'numeric', minute: 'numeric',})
        },
        getTime(time){
            time = time.split(":")
            let d = new Date()
            d.setHours(time[0],time[1])
            return d.getUTCHours()+':'+d.getUTCMinutes()
        },
        editLigth(ligth){
            this.edit = true
            this.settings = false
            this.paramLitht={
                id: ligth.id,
                // purposeUser: ligth.purposeUser,
                name: ligth.name,
                parameters: {
                    type: ligth.parameters.type,
                    name: ligth.parameters.name,
                    number: ligth.parameters.number
                },
                purpose: {
                    type: ligth.purpose.type,
                    value: ligth.purpose.value
                }

            }

            this.paramLithtNumber = ligth.parameters.number
            this.show = true

        },
        editTask(task){
            this.isCoorAdvMode = false
            this.activeIsCoorAdvMode()
            this.edit = true
            this.settings = false
            this.param = {
                id: task.id,
                planet: {name: task.planet, path:task.path},
                type: task.type,
                from: task.from,
                disposable: task.disposable,
                pauseWith: this.getTimeObr(task.pauseTo),
                pauseTo: this.getTimeObr(task.pauseWith),
                coordinatesFromArr: task.coordinatesFrom,
                coordinatesToArr: task.coordinatesTo,
                fleets: task.fleets,
                // withOwner: task.withOwner,
                owner: task.owner,
                spaceships: task.spaceships/task.fleets,
            }
            if (task.pauseWith == task.pauseTo) {this.param.pauseWith = ''; this.param.pauseTo = ''; this.always = true}else {this.always = false}
            // this.isCoorAdvMode = true
            this.show = true
        },
        deleteLigth(id){
            this.deleteTaskId = ''
            this.$axios.delete("signals?id="+id).then(()=>{
                //res
                this.getTasks()
            })
        },
        deleteTask(id){
            let bodyFormData = new FormData();
            bodyFormData.append('id', id);
            this.deleteTaskId = ''
            this.$axios.delete("tasks/sky", { data: bodyFormData }).then(()=>{
                //res
                this.getTasks()
            })
        },

        createLigth(){
            if (!this.paramLitht.name || !this.paramLithtNumber || !this.paramLitht.parameters.name || this.paramLitht.parameters.name == 'sel') {
                return
            }
            if (this.paramLitht.purpose.value == '') { //Если значения филтра нет то отмечаем это
                this.paramLitht.purpose.type = 0
            }
            this.paramLitht.parameters.number = this.paramLithtNumber.replace(/\s+/g, '')

            if (this.edit){
                this.$axios.post("/signals", this.paramLitht).then(res=>{
                    if (!res.data.success && res.data.message == "Name not found") {
                        switch (this.paramLitht.purpose.type) {
                            case '1':
                                this.$notify({ type: 'error', text: 'Планета не найдена!'})
                                break;
                            case "2":
                                this.$notify({ type: 'error', text: 'Ник не найден!' })
                                break;
                            case "3":
                                this.$notify({ type: 'error', text: 'Альянс не найден!' })
                                break;
                        }
                    }
                    else{
                        this.newTask(true)
                        this.getTasks()
                    }
                })
            }else {
                this.$axios.post("/signals", this.paramLitht).then(res=>{
                    if (!res.data.success && res.data.message == "Name not found") {
                        switch (this.paramLitht.purpose.type) {
                            case "1":
                                this.$notify({ type: 'error', text: 'Планета не найдена!' })
                                break;
                            case "2":
                                this.$notify({ type: 'error', text: 'Ник не найден!' })
                                break;
                            case "3":
                                this.$notify({ type: 'error', text: 'Альянс не найден!' })
                                break;
                        }
                    }
                    else{
                        this.newTask(true)
                        this.getTasks()
                    }
                })
            }


        },
        _getPlanetPath(planetName) {
            for (let p of this.userPlanets) if (p.name === planetName) {
                return p.path.split(':').map(i => parseInt(i))
            }

            return null
        },
        createTask(){
            if (this.numCad == 1) {
                return this.createLigth()
            }
            // if (this.tasks.length>5) {return}
            if (!this.param.fleets || parseInt(this.param.fleets)<1) {
                this.error = 'fleets'
                return
            }

            if (!this.param.spaceships || parseInt(this.param.spaceships) < 1) {
                this.error = 'spaceships'
                return
            }

            console.log(this.param)

            if (!this.param.planet.name) {
                return this.$notify({
                    type: 'error',
                    text: 'Необходимо выбрать планету с которой будет производиться миссия'
                })
            }

            let data = {
                "coordinatesFrom": [],
                "coordinatesTo": [],
                "disposable": this.param.disposable,
                "spaceships": parseInt(this.param.spaceships) * parseInt(this.param.fleets),
                "pauseWith": "",
                //"withOwner": this.param.withOwner,
                "owner": this.param.owner,
                "pauseTo": "",
                "fleets": parseInt(this.param.fleets),
                "planet": this.param.planet.name,
                //"from": this.param.planet.path ? this.param.planet.path.split(":") : null
                "from": this._getPlanetPath(this.param.planet.name)
            }

            if (this.edit) {
                data.id = this.param.id
            }

            if (parseInt(!this.param.coordinatesToArr[0]) > -1 
             || parseInt(!this.param.coordinatesToArr[1]) > -1 
             || parseInt(!this.param.coordinatesToArr[2]) > -1) {
                data.coordinatesFrom = [0,0,0]
                data.coordinatesTo = [20,0,0]
            } else {
                if (this.isCoorAdvMode) {
                    // console.log(this.param.coordinatesFromArr, this.param.coordinatesToArr);
                    data.coordinatesFrom = (parseInt(this.param.coordinatesFromArr[0]) > -1 
                                         || parseInt(this.param.coordinatesFromArr[1]) > -1 
                                         || parseInt(this.param.coordinatesFromArr[2]) > -1) ? this.param.coordinatesFromArr : [0,0,0]
                    data.coordinatesTo = this.param.coordinatesToArr
                } else {
                    data.coordinatesFrom = [
                        parseInt(this.numGalaxy1), 
                        parseInt(this.numSector1.split('-')[0]), 
                        parseInt(this.numPlanet1.split('-')[0])
                    ]

                    data.coordinatesTo = [
                        parseInt(this.numGalaxy1), 
                        parseInt(this.numSector1.split('-')[1]), 
                        parseInt(this.numPlanet1.split('-')[1])
                    ]
                }
            }
            if (this.always || !this.param.pauseWith || !this.param.pauseTo) {
                data.pauseWith = '00:00'
                data.pauseTo = '00:00'
            }else{
                data.pauseWith = this.getTime(this.param.pauseTo)
                data.pauseTo = this.getTime(this.param.pauseWith)
            }
            // console.log(data);
            this.disabled = true
            if (this.edit)
                this.$axios.put("tasks/sky", data).then(()=>{
                    this.disabled = false
                    this.newTask(true)
                    this.getTasks()
                }).catch (() => {
                    return this.$notify({
                        type: 'error',
                        text: `Не удалось сохранить изменения. Проверьте введенные данные и попробуйте снова`
                    })
                })
            else
                this.$axios.post("tasks/sky", data).then(()=>{
                    this.disabled = false
                    this.newTask(true)
                    this.getTasks()
                }).catch (() => {
                    return this.$notify({
                        type: 'error',
                        text: `Не удалось сохранить изменения. Проверьте введенные данные и попробуйте снова`
                    })
                })
        },
        getLigth(){
            if (!this.isProVersion) {
                return this.tasks = []
            }

            this.load = true
            this.$axios.get("/signals").then(res=>{
                this.load = false
                if (res.data) {
                    this.tasks = res.data
                }
            }).catch(() => {
                return this.$notify({
                    type: 'error',
                    text: `Не удалось создать подгрузить маяки`
                })
            })
        },
        getTasks(){
            if (this.numCad==1) {
                return this.getLigth()
            }
            this.load = true
            this.$axios.get("tasks/sky").then(res=>{
                this.load = false
                if (res.data)
                    this.tasks = res.data.items
            })

        },
        newLight(){
            this.paramLitht = {
                // purposeUser: "",
                name: "",
                parameters: {
                    type: 0,
                    name: "sel",
                    number: ''
                },
                purpose: {
                    type: 0,
                    value: ""
                }

            }
        },
        newTask(par = false){
            this.edit = false
            this.isCoorAdvMode = false
            this.settings = false
            if (par)
                this.show = false
            else
                this.show = !this.show

            if (this.numCad == 1) {this.newLight()}
            this.param = {
                planet: this.param.planet,
                type: 'pioneer',
                pauseWith: '',
                pauseTo: '',
                disposable: false,
                coordinatesFromArr: ['0','0','0'],
                coordinatesToArr: ['20','0','0'],
                //withOwner: false,
                owner: 'none',
                fleets: 1,
                spaceships: 1,
            }

        },
        maxval(num){
            if (num == 2 && this.param.coordinatesFromArr[num] > 20) {
                this.param.coordinatesFromArr[num] = 20
            }
            if (this.param.coordinatesFromArr[num] > this.param.coordinatesToArr[num]) {
                this.param.coordinatesToArr[num] = this.param.coordinatesFromArr[num]
            }
        },
        minval(num){
            if (num == 2 && this.param.coordinatesToArr[num] > 20) {
                this.param.coordinatesToArr[num] = 20
            }
            if (this.param.coordinatesFromArr[num] > this.param.coordinatesToArr[num]) {
                console.log(this.param.coordinatesFromArr[num], this.param.coordinatesToArr[num]);
                this.param.coordinatesFromArr[num] = this.param.coordinatesToArr[num]
            }
        },
        activeIsCoorAdvMode(){
            this.isCoorAdvMode = !this.isCoorAdvMode
            this.param.coordinatesFromArr = [
                parseInt(this.numGalaxy1), 
                parseInt(this.numSector1.split('-')[0]), 
                parseInt(this.numPlanet1.split('-')[0])
            ]

            this.param.coordinatesToArr = [
                parseInt(this.numGalaxy1), 
                parseInt(this.numSector1.split('-')[1]), 
                parseInt(this.numPlanet1.split('-')[1])
            ]
        },
        closeLHouseMessagesPopup() {
            this.lhouseMessages = []
        },
        async openLHouseMsgPopup(lHouseId) {
            const { data } = await this.$axios.get('/signals/task/messages', { params: { id: lHouseId } })

            if (data.length && data.length < 1) {
                // throw or handler
                return
            }

            this.lhouseMessages = data
            this.markAllMsgReaded(lHouseId)
        },
        async markAllMsgReaded(lHouseId) {
            const formData = new FormData()
            formData.append('id', lHouseId)

            return this.$axios.put('/signals/task/messages', formData)
        },
        async setSkyStatus(taskId, newStatus) {
            const fd = new FormData()
            fd.append('id', taskId)
            fd.append('status', newStatus)

            const { status } = await this.$axios.put(`/tasks/sky/status`, fd)

            if (status !== 200 ) {
                return this.$notify({ type: "error", text: "Не удалось сменить статус задачи" })
            }
            
            this.getTasks()

            return this.$notify({ type: 'success', text: 'Статус задачи успешно изменен' })
        },
        getStatusText(statusCode) {
            switch(statusCode) {
                case 'running':
                    return `Запущен`
                case 'pause':
                    return `Приостановлен`
                case 'deleted':
                    return `Остановлен`
                default:
                    return `Неизвестен`
            }
        }
    },
    data(){
        return{
            paramLithtNumber: '',
            purpose: ["без фильтра", "по планете", "по нику", "по альянсу"],
            numCad: 0,
            edit: false,
            numGalaxy1:'0',
            numSector1:'0-100',
            numPlanet1:"0-20",
            textBtn: 'Сохранить',
            settings: false,
            // queueTelescope: [],
            deleteTaskId: '',
            load: false,
            disabled: false,
            error: false,
            tasks: [],
            show: false,
            always: true,
            nongran: false,
            itemsList: {'pioneer': 'Исследование', 'lighthouse':'Создать маяк'},
            timerId:'',
            param:{
                planet: { name: '', path: null },
                pauseWith: '',
                pauseTo: '',
                disposable: false,
                coordinatesFromArr: [0,0,0],
                coordinatesToArr: [20,0,0],
                fleets: 1,
                //withOwner: false,
                owner: 'none',
                spaceships: 1,
            },
            paramLitht:{
                name: "",
                parameters: {
                    type: 0,
                    name: "sel",
                    number: ''
                },
                purpose: {
                    type: 0,
                    value: ""
                }
            },
            categorys: categorys,
            selectCategory:"0",
            selectField:"sel",
            isCoorAdvMode: false,
            lhouseMessages: []
        }
    },
    watch:{
        paramLithtNumber(value){
            value = value + ''
            if (isNaN(parseInt(value.replace(/\s+/g, '')))) {
                return this.paramLithtNumber = ''
            }
            this.paramLithtNumber = parseInt(value.replace(/\s+/g, '')).toLocaleString()
        },
        numCad(){
            this.tasks = []
            this.getTasks()
        },
        // selectedPlanetName(newValue) {
        //     //this.params.planet = 
        // }
    },
    destroyed(){
        clearInterval(this.timerId)
    }

}
</script>

<style media="screen" scoped="">
.popupPodtMask{
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--cad-popuppodtmask-bg);
}
.popupPodt {
    z-index: 99999;
    left: 50%;
    top: 25%;
    width: 350px;
    text-align: center;
    transform: translate(-50%, -50%);
    position: absolute;
    background: var(--cad-popupdt-bg);
}

.popupPodt--msgs {
    width: 600px;
    top: 45%;
}

.popupPodt--msgs .msgs-list {
    text-align: left;
    max-height: 350px;
    overflow: auto;
}

.mt20{
    margin-top: 25px;
}
.btn_task{
    padding: 3px 20px;
    border: none;
    background: var(--cad-btn-task-bg);
    border: 1px solid var(--cad-btn-task-border-color);
    color: var(--cad-btn-task-color);
}
.btn_task:hover{
    background: var(--cad-btn-task-hover-bg);
    /* color: #2f3136; */
}
.btn_task:focus{
    outline: none;
}
.nonnumber::-webkit-outer-spin-button,
.nonnumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.nonnumber[type=number] {
  -moz-appearance: textfield;
}
.mdidth{
    font-size: 14px;
    width: 73px;
}
.cursor-pointer{
    cursor: pointer;
}
.time_piker::-webkit-calendar-picker-indicator {
    /* color: white; */
   filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
}
.inpCoard{
    /* margin-left:5px; */
    width: 30px;
}
.inpCoard::-webkit-outer-spin-button,
.inpCoard::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.inpCoard[type=number] {
    -moz-appearance: textfield;
}
.time_piker{
    background: var(--cad-time-picker-bg);
    border: 1px solid var(--cad-time-picker-border-color);
    color: var(--cad-time-picker-color);
}
.time_piker:focus{
    outline: none;
}
.mutedz{
    border: 1px solid var(--cad-mutedz-color) !important;
    color: var(--cad-mutedz-color) !important;
}
.mt{
    margin-top: 15px;
}
.bb{
    padding-bottom: 15px;
    border-bottom: 2px solid var(--cad-bb-border-color);
}
.newTask{
    box-shadow: 0px 0px 50px var(--cad-new-task-box-shadow-color);
    font-size: 15px;
    color: var(--cad-new-task-color);
    padding: 15px;
    font-weight: 300;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--cad-new-task-bg);
    min-width: 415px;
    min-height: 400px;
    z-index: 999;
}
td{
    border: 1px solid var(--cad-td-border-color);
}
tr{
    height: 30px;
}
.main_table > thead{
    background: var(--cad-main-table-thead-bg);
}

.main_table{
    font-size: 14px!important;
    color: var(--cad-main-table-color);
    font-weight: normal;
    width: 100%;
}
.paddingtop50{
    padding-top: 30px;
}
.h100{
    height: 100%;
}
.shadow{
    box-shadow: 0px 0px 10px var(--cad-shadow-box-shadow-color);
}
body{
    overflow-y: hidden;
}
.hmin100{
    min-height: 100%;
}
::-webkit-scrollbar {  width: 10px;}

::-webkit-scrollbar-track-piece { background-color: var(--app-scrollbar-piece-bg-color);}
::-webkit-scrollbar-thumb { height: 50px; background-color: var(--app-scrollbar-thumb-bg-color);}

::-webkit-scrollbar-corner { background-color: var(--app-scrollbar-corner-bg-color);}
::-webkit-resizer { background-color: var(--app-resizer-bg-color);}
.mt1{
    margin-top: 2px;
}
.mb1{
    margin-bottom: 4px;
}
.fonPlanet {
  /* box-shadow: 0 0 85px #000; */
  /* padding-top: 30px; */
  position: relative;
    background: var(--cad-fonplanet-bg);
    height: calc(100% - 50px);
  max-height: 100vh;
  width: 100%;
    filter: blur();
}
.yellow_main{
  color: var(--cad-yellow-main-color);
}
.mintext{
    font-size: 13px;
}

.mrauto{
    margin-left: auto;
}
.link_plan{
    color: var(--cad-link-plan-color);
    font-size: 12px;
    margin-top: 4px;
}

.link_plan.hov:hover{
    color: var(--cad-link-plan-hover-color);
    font-weight: 400;
}
.LogComponent{
    /* height: 60px; */
}
input[type=checkbox] { display: none; }
.pseudocheckbox::before {
    content: "\00A0";
    display: inline-block;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    /* background-color: #dad9da; */
    background: var(--cad-pseudobox-bg);
    border: 1px solid var(--cad-pseudobox-border-color);
    border-radius: 0px;
    vertical-align: baseline;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: var(--cad-pseudobox-color);
    margin-bottom: 0;
    cursor: pointer;
}
.error{
    border: 1px solid var(--cad-error-border-color);
}
input[type=checkbox]:checked + .pseudocheckbox::before { content: "\2713"; }

.text-center {
    text-align: center;
}
select>option{
  /* color: black; */
  background: var(--cad-option-bg);
}
.numInp{
    width: 80px;
    background: var(--cad-numinp-bg);
    border: 1px solid var(--cad-numinp-border-color);
    /* border: none; */
    color: var(--cad-numinp-color);
}
.numInp:focus{
    outline: none;
}
.numInp::-webkit-outer-spin-button,
.numInp::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.numInp[type=number] {
    -moz-appearance: textfield;
}

.popup-settings {
    cursor: pointer;
}

.popup-settings img {
    width: 20px;
    height: 20px;
}
.advanced-coords input {
    width: 72px;
}
.action-icon {
    height: 17px;
}

.close-cross {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

tr.task-status-deleted {
    opacity: 0.4;
}

[data-theme="light"] .action-icon {
    filter: invert(0.7)
}
</style>
